@import './mixins.scss';
@import url('https://fonts.googleapis.com/css?family=Droid+Serif:400,700');


@font-face {
    font-family: 'Verlag-Book';
    src: url('../fonts/Verlag-Book/Verlag-Book.otf') format("opentype");
    src: url('../fonts/Verlag-Book/Verlag-Book.eot');
    src: url('../fonts/Verlag-Book/Verlag-Book.eot') format('embedded-opentype'), url('../fonts/Verlag-Book/Verlag-Book.woff') format('woff'), url('../fonts/Verlag-Book/Verlag-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Verlag-Book';
    src: url('../fonts/Verlag-Book/Verlag-Bold.otf') format("opentype");
    src: url('../fonts/Verlag-Book/Verlag-Bold.eot');
    src: url('../fonts/Verlag-Book/Verlag-Bold.eot') format('embedded-opentype'), url('../fonts/Verlag-Book/Verlag-Bold.woff') format('woff'), url('../fonts/Verlag-Book/Verlag-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

body {
    margin: 0;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
    line-height: 1.42857143;
    webkit-font-smoothing: antialiased;
    scroll-behavior: smooth;
    background-color: #000;
}