@import '../../../stylesheets/mixins.scss';
@font-face {
    font-family: 'Verlag-Book';
    src: url('../../../fonts/Verlag-Book/Verlag-Book.otf') format("opentype");
    src: url('../../../fonts/Verlag-Book/Verlag-Book.eot?d7yf1v');
    src: url('../../../fonts/Verlag-Book/Verlag-Book.eot?#iefixd7yf1v') format('embedded-opentype'), url('../../../fonts/Verlag-Book/Verlag-Book.woff?d7yf1v') format('woff'), url('../../../fonts/Verlag-Book/Verlag-Book.ttf?d7yf1v') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Verlag-Book';
    src: url('../../../fonts/Verlag-Book/Verlag-Bold.otf') format("opentype");
    src: url('../../../fonts/Verlag-Book/Verlag-Bold.eot?d7yf1v');
    src: url('../../../fonts/Verlag-Book/Verlag-Bold.eot?#iefixd7yf1v') format('embedded-opentype'), url('../../../fonts/Verlag-Book/Verlag-Bold.woff?d7yf1v') format('woff'), url('../../../fonts/Verlag-Book/Verlag-Bold.ttf?d7yf1v') format('truetype');
    font-weight: bold;
    font-style: normal;
}

.navbar {
    box-shadow: none;
    color: #fff;
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030;
    display: grid;
    transition: all 0.3s ease 0s;
    border-radius: 0 0 5px 5px;
    a{
        text-decoration: none;
        color: #fff;
    }
    @include breakpoint(md) {
        grid-template-columns: [full-start]minmax(1.875rem, 1.875rem) [center-start] repeat(8, minmax(min-content, 1fr)) [center-end] minmax(1.875rem, 1.875rem)[full-end];
        background-color: transparent;
        padding: 2rem 0;
    }
    @include breakpoint(lg) {
        padding: 2rem 0;
        grid-template-columns: [full-start]minmax(30px, 30px) 1fr [center-start] repeat(8, minmax(min-content, 150px)) [center-end] 1fr minmax(30px, 30px)[full-end];
    }
    @include breakpoint(xl) {
        grid-template-columns: [full-start]minmax(30px, 30px) 1fr [center-start] repeat(8, minmax(min-content, 180px)) [center-end] 1fr minmax(30px, 30px)[full-end];
    }
    &.scrolled {
        background-color: #fff;
        color: #000;
        box-shadow: 0 4px 18px 0 rgba(0, 0, 0 , .12), 0 7px 10px -5px rgba(0, 0, 0, .15);
        .navbar-logo {
            color: #000;
        }
        .nav-links {
            color: #000;
            button {
                color: #000;
            }
        }
    }
    &-logo {
        display: grid;
        grid-column: 3/7;
        grid-row: 1;
        align-self: center;
        font-size: 1.25rem;
        text-transform: uppercase;
        color: #fff;
        text-decoration: none;
        font-family: "Verlag-Book","Helvetica Neue", Helvetica, Arial, sans-serif;

        @include breakpoint(md) {
            grid-column: 2/7;
        }
        @include breakpoint(lg) {
            grid-column: 3/7;
        }

    }
    .nav-links {
        grid-row: 1;
        display: inherit;
        color: #fff;
        align-self: end;
        grid-template-columns: repeat(5, minmax(min-content, 11.25rem));
        @include breakpoint(md) {
            grid-column: 7/10;
        }
        @include breakpoint(lg) {
            grid-column: 8/11;
        }
        @include breakpoint(lg) {
            grid-template-columns: repeat(5, 9.375rem);
            // margin-left: -80px;
            // gap: 1.25rem;
        }
        & > div {
            position: relative;
            display: inherit;
            width: 100%;
            // justify-self: stretch;
            text-align: center;
            &.active {
                ul {
                    display: block;
                    opacity: 1;
                    transform: scale(1);
                }
            }
            button {
                font-size: 1.125rem;
                text-transform: uppercase;
                font-weight: 400;
                cursor: pointer;
                background-color: transparent;
                border-color: transparent;
                color: #fff;
                font-family: "Verlag-Book","Helvetica Neue", Helvetica, Arial, sans-serif;

                .svg-inline--fa,
                path {
                    pointer-events: none;
                }
            }
        }
    }
}
