@import '../../stylesheets/mixins.scss';

.footer {
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 2rem;
    flex-direction: column;
    @include breakpoint(md) {
        flex-direction: row;
        padding: 1rem 15px 1rem 15px;
        // margin: 1rem 0;
        justify-content: space-between;
        align-items: center;
    }
    background-color: #F5F5F5;
    color: #9A9A9A;
    padding-bottom: 2rem;
    .links {
        display: inherit;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        margin-bottom: 2rem;
        @include breakpoint(md) {
            flex-direction: row;
            justify-content: space-evenly;
            margin-bottom: 0;
            align-items: flex-end;
            width: 40%;
            order: 1;
        }
        h6 {
            margin: 1rem 0;
            font-size: .875rem;
            font-family: 'Droid Serif', "Helvetica Neue", "Helvetica","Arial";
            font-weight: 300;
            @include breakpoint(md) {
                margin: 0;
            }
        }
        .textbox {
            display: inherit;
            @include breakpoint(md) {
            }
            &-customer {
                @include breakpoint(sm) {
                }
            }
            &-company {
                @include breakpoint(sm) {
                }
            }
        }

    }
    .socialmedia {
        @include breakpoint(sm) {
            
            
        }
        hr {
            border: 0;
            border-top: 1px solid #eee;
            border-color: #444444;
            height: 0;
        }
        &-links {
            display: flex;
            flex-direction: row;
            justify-content: center;
            @include breakpoint(md) {
                // justify-content: flex-end;
                width: 20%;
                order: 3;
            }
            a {
                color: #9A9A9A;
                cursor: pointer;
                width: 35px;
                height: 35px;
                text-decoration: none;
                text-align: center;
                font-size: 20px;
                font-weight: normal;
                touch-action: manipulation;
                transition: color 0.3s;
                margin: 0 .5rem;
                text-decoration: none;
                @include breakpoint(md) {
                    margin: 0 .5rem;
                    width: auto;
                    height: auto;
                }
            }
            &_linkedin {
                &:hover {
                   color: rgba(40, 103, 178, 1.0);
                }
            }
            &_twitter {
                &:hover {
                    color: rgba(29, 161, 242, 1.0);
                }
            }
            &_instagram {
                &:hover {
                    &:hover {
                        color:  #fd5949;
                        -webkit-background-clip: text;
                        background-clip: text;
                        -webkit-text-fill-color: transparent;
                    }
                }
            }
        }
    }
    .footer-center{
        @include breakpoint(md){
            order: 2;
            width: 40%;
        }
        .copyright {
            margin: 0;
            text-align: center;
            color: #777777;
            font-size: .875rem;
            font-family: 'Droid Serif', "Helvetica Neue", "Helvetica","Arial";
            font-weight: 300;
            padding-top: 1.5rem;
            @include breakpoint(sm) {
                padding-top:0;
            
            }
        }
    }
}
