@import '../../stylesheets/mixins.scss';
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/_mixins.scss";



.home {
    display: grid;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    grid-template-columns: [full-start]minmax(30px, 30px) [center-start] repeat(8, minmax(min-content, 1fr)) [center-end] minmax(30px, 30px)[full-end];
    @include breakpoint(lg) {
        grid-template-columns: [full-start]minmax(30px, 30px) 1fr [center-start] repeat(8, minmax(min-content, 150px)) [center-end] 1fr minmax(30px, 30px)[full-end];
    }
    @include breakpoint(xl) {
        grid-template-columns: [full-start]minmax(30px, 30px) 1fr [center-start] repeat(8, minmax(min-content, 180px)) [center-end] 1fr minmax(30px, 30px)[full-end];
    }
    .billboard {
        background-image: url('https://res.cloudinary.com/izzyhv/image/upload/f_auto,q_auto:eco/v1/portfolio/isaac_vazquez_portfolio_hero.jpg');
        // background-image: url('https://picsum.photos/1600/2000');
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        color: #fff;
        display: grid;
        min-height: 95vh;
        position: relative;
        grid-column: 1/-1;
        grid-template-columns: [full-start]minmax(30px, 30px) [center-start] repeat(8, minmax(min-content, 1fr)) [center-end] minmax(30px, 30px)[full-end];
        @include breakpoint(md) {
            height: 90vh;
            min-height: 650px;
            background-position: center;
            background-image: url('https://res.cloudinary.com/izzyhv/image/upload/f_auto,q_auto:eco/v1/portfolio/isaac_vazquez_portfolio_hero.jpg');
        }
        @include breakpoint(lg) {
            height: 90vh;
            grid-template-columns: [full-start]minmax(30px, 30px) 1fr [center-start] repeat(8, minmax(min-content, 150px)) [center-end] 1fr minmax(30px, 30px)[full-end];
        }
        @include breakpoint(xl) {
            grid-template-columns: [full-start]minmax(30px, 30px) 1fr [center-start] repeat(8, minmax(min-content, 180px)) [center-end] 1fr minmax(30px, 30px)[full-end];
        }
        &::before {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background-image: linear-gradient(to left, rgba(0, 0, 0, .4), rgba(0, 0, 0, .4));
            z-index: 0;
        }
        .background {
            -webkit-backdrop-filter:saturate(180%) blur(3px);
            backdrop-filter: saturate(180%) blur(3px);
            position: absolute;
            width: 100vw;
            height: 100%;
        }
        .container {
            z-index: 1;
            grid-column: 1/-1;
            margin: auto 0;
            padding: 6rem 0 4rem;
            align-self: center;
            @include breakpoint(md) {
                grid-column: center-start/center-end;
            }
        }

        h1 {
            text-align: center;
            font-size: 3rem;
            font-weight: 700;
            text-transform: uppercase;
            margin: 0;
            font-family: "Slim-Joe", "Helvetica Neue", "Arial";
            @include breakpoint(sm) {
                font-size: 3rem;
            }
            @include breakpoint(md) {
                margin-top: 0;
                font-size: 5rem;
            }
        }
        h2 {
            letter-spacing: 5px;
            margin: 0;
            text-align: center;
            font-size: 1rem;
            font-family: "Verlag-Book","Helvetica Neue",Helvetica,Arial,sans-serif;
            text-transform: uppercase;
            font-weight: 400;
            padding-left: 8px;
            padding-right: 8px;
            @media only screen and (min-width: 475px) {
                font-size: 1rem;
                padding-left: 0;
                padding-right: 0;
            }
            @include breakpoint(lg) {
                margin: auto;
                width: 800px;
            }
        }
        &_textbox {
            padding-left: auto;
            padding-right: auto;
            @include breakpoint(md) {
                padding: 0;
            }
            p {
                text-align: left;
                margin-bottom: 2rem;
                @include breakpoint(sm) {
                    font-size: 1.25rem;
                    max-width: 600px;
                }
            }
            a{
                display: flex;
                justify-content: center;
                max-width: 160px;
                padding: 0;
                margin-left: auto;
                margin-right: auto;
                margin-top: 1rem;
            }
            button{
                margin-top: 1rem;
                
                color: #000;
                background-color: #E3D9C2;
                border-color: #E3D9C2;
                box-shadow: 0 2px 2px 0 rgba(146, 113, 75, .14), 0 3px 1px -2px rgba(146, 113, 75,.20), 0 1px 5px 0 rgba(146, 113, 75,.12);
                position: relative;
                max-width: 200px;
                padding: 12px 30px;
                margin: 0.3125rem 1px;
                font-size: 0.75rem;
                font-weight: 400;
                line-height: 1.428571;
                text-decoration: none;
                text-transform: uppercase;
                letter-spacing: 0;
                outline: 0;
                border: 0;
                border-radius: 3px;
                transition: box-shadow 0.2s cubic-bezier(.4,0,1,1),background-color 0.2s cubic-bezier(.4,0,.2,1);
                will-change: box-shadow,transform;
                &:focus,
                &:hover {
                    color: #fff;
                    background-color: #c7b385;
                    border-color: #c7b385;
                    box-shadow: 0 14px 26px -12px rgba(0 ,0, 9, .82), 0 4px 23px 0 rgba(0, 0, 0,.32), 0 8px 10px -5px rgba(0, 0, 9, .30);
                }
            }

        }

    }



}
@keyframes rotating {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
};
@keyframes text-expand {
    from {
        letter-spacing: -10px;
        opacity: 0;
    }
    to {
        opacity: 1;
        letter-spacing: 15px;
        animation-fill-mode: forwards;
    }
}
