
@import '../../../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import "../../../../node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
@import '../../../stylesheets/mixins.scss';
@font-face {
    font-family: 'Verlag-Book';
    src: url('../../../fonts/Verlag-Book/Verlag-Book.otf') format("opentype");
    src: url('../../../fonts/Verlag-Book/Verlag-Book.eot?d7yf1v');
    src: url('../../../fonts/Verlag-Book/Verlag-Book.eot?#iefixd7yf1v') format('embedded-opentype'), url('../../../fonts/Verlag-Book/Verlag-Book.woff?d7yf1v') format('woff'), url('../../../fonts/Verlag-Book/Verlag-Book.ttf?d7yf1v') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}
@font-face {
    font-family: 'Verlag-Book';
    src: url('../../../fonts/Verlag-Book/Verlag-Bold.otf') format("opentype");
    src: url('../../../fonts/Verlag-Book/Verlag-Bold.eot?d7yf1v');
    src: url('../../../fonts/Verlag-Book/Verlag-Bold.eot?#iefixd7yf1v') format('embedded-opentype'), url('../../../fonts/Verlag-Book/Verlag-Bold.woff?d7yf1v') format('woff'), url('../../../fonts/Verlag-Book/Verlag-Bold.ttf?d7yf1v') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: fallback;
}
@import url(//fonts.googleapis.com/css?family=Droid+Serif);
.send_email {
    padding: 0 15px;
    background-color: #fff;
    background-position: 50% 25%;
    background-size: cover;
    color: #000;
    display: grid;
    position: relative;
    grid-template-columns: [full-start]minmax(30px, 30px) [center-start] repeat(8, minmax(min-content, 1fr)) [center-end] minmax(30px, 30px)[full-end];
    @include breakpoint(lg) {
        grid-template-columns: [full-start]minmax(30px, 30px) 1fr [center-start] repeat(8, minmax(min-content, 150px)) [center-end] 1fr minmax(30px, 30px)[full-end];
    }
    @include breakpoint(xl) {
        grid-template-columns: [full-start]minmax(30px, 30px) 1fr [center-start] repeat(8, minmax(min-content, 180px)) [center-end] 1fr minmax(30px, 30px)[full-end];
    }


    .container {
        z-index: 1;
        display: inherit;
        grid-column: 1/-1;
        @include breakpoint(sm){
            align-self: center;
        }
        @include breakpoint(lg){
            grid-column: center-start/center-end;
        }
        // justify-content: space-between;
        h3 {
            padding-top: 4rem;
            font-size: 1.5rem;
            line-height: 1.5em;
            font-weight: normal;
            // margin-right: 5rem;
            grid-column: 1/-1;
            text-transform: uppercase;
            text-align: center;
            margin: 0;
            font-family: "Verlag-Book","Helvetica Neue", Helvetica, Arial, sans-serif;
            @include breakpoint(md) {
                margin: auto 0;
                text-align: left;
                font-size: 2.25rem;
                grid-column: 1/-1;
                text-align: center;
            }

        }
        h6{
            text-align: center;
            margin: 0;
            margin-bottom: 1rem;
            font-size: 1rem;
            font-family: "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
            font-weight: 300;
            color: #777777;
            @include breakpoint(sm){
                grid-column: 1/-1;
                margin-bottom: 2rem;
            }
            @include breakpoint(smd) {
                font-size: 1.2rem;
            }
        }
        .separator-container{
            grid-column: 1/-1;
            text-align: center;
            color: #E3D9C2;
            margin: 0 auto;
            width: 11em;
            .separator.line-separator{
                width: 100%;
                color: #000;
                margin: .5rem auto;
                position: relative;
                @include breakpoint(md) {
                    margin: 1rem auto;
                }
                &::before{
                    float: left;
                    display: block;
                    width: 40%;
                    content: " ";
                    margin-top: 7px;
                    border: 1px solid #E3D9C2;
                }
                &::after{
                    float: right;
                    display: block;
                    width: 40%;
                    content: " ";
                    margin-top: 7px;
                    border: 1px solid #E3D9C2;
                }
            }
        }
        .calendly-inline-widget{
            min-height:1000px;
            @include breakpoint(md) {
                min-height:650px;
            }
        }
    }
    form {
        grid-column: 1/-1;
        display: inherit;
        justify-items: center;
        align-items: center;
        flex-direction: column;
        margin: 0 0 2rem 0;
        opacity: 1;
        transition: opacity 0.3s linear;
        @include breakpoint(sm) {
            // justify-items: end;
            justify-self: end;
            grid-column: 1/-1;
            margin: 0;
            width: 100%;
            display: flex;
            flex-direction: column;
        }
        @include breakpoint(md) {
            margin-bottom: 4rem;
        }
        .form{
            &-top{
                display: flex;
                flex-direction: column;
                width: 100%;
                justify-content: space-between;
                position: relative;
                @include breakpoint(md){
                    margin-bottom: 2rem;
                    flex-direction: row;
                }
                .form-item{
                    display: flex;
                    justify-content: center;
                    &:not(:first-of-type){
                        input{

                            @include breakpoint(md){
                                margin-left: 2rem;
                            }
                        }
                    }
                    input{
                        width: 100%;
                        min-height: 2rem;
                        
                        @include breakpoint(md){
                            width: 100%;
                            min-height: 2.5rem;
                            
                        }
                     
                    }
                    p.err{
                        @include breakpoint(md){
                            position: absolute;
                            margin-top: 50px;
    
                        }
                    }
                }
            }
            &-bottom{
                display: flex;
                flex-direction: column;
                width: 100%;
                .form-item{
                    textarea{

                        min-height: 5rem;
                        @include breakpoint(md){
                            min-height: 10rem;
                            margin-bottom: 2rem;
                        }
                    }
                }
            }
            &-item {
                width: 100%;
                margin-left: auto;
                margin-right: auto;
                input,textarea{
                    padding:0;
                    width: 100%;
                    font-size: 1rem;
                    background-color: transparent;
                    border: 1px solid #d0cdbc;
                    text-indent: 1rem;
                    color: #444444;
                    margin-bottom: 1.5rem;
                    font-family: 'Verlag-Book',"Helvetica Neue",Helvetica,Arial,sans-serif;
                    @include breakpoint(md){
                        margin-bottom: .5rem;
                       
                    }
                    &:focus {
                        outline: none;
                        background-color: #fff;
                    }
                    &:focus-visible {
                        outline: none;
                        background-color: transparent;
                    }
                    &:-internal-autofill-selected {
                        background-color: transparent !important;
                    }
                    &::placeholder {
                        color: #ededed;
                        margin-left: 0;
                        padding-left: 0;
                    }
                }
            }
        }
       
        textarea{
            margin: 0;
            width: 100%;
            height: auto;
            @include breakpoint(md){
                width: 98%;
            }
        }
        input:-webkit-autofill,
        input:-webkit-autofill:active,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:hover {
            -webkit-box-shadow: 0 0 0 30px transparent inset !important;
        }
        p {
            margin-top: -.8rem;
            color: #bf1650;
            font-weight: 400;
        }

        p::before {
            display: inline;
            content: "⚠ ";
        }
        .submit {
            max-width: 400px;
            transition-property: all;
            
            &.pending{
                max-width: 20px;
                padding: 20px;
                content: "ur mom ";
                animation-name: spin;
                animation-duration: 5000ms;
                animation-iteration-count: infinite;
                animation-timing-function: linear; 
                color: transparent;
                border: 5px solid #000;
                border-left-color: #f7f7f7;
                border-radius: 50%;
                background: transparent;
                transition-property: all;
                transition-duration: .03s;
                &::after{
                    content: " ";
                }
            }
            &.complete{
                &::after{
                    content: icon;
                }
            }
        }
        .active {
            display: block;
        }
        .d-none {
            display: none;
        }

    }
    .email-status{
        color:#03AC13 ;
        font-size: 1.5rem;
    }
    .submit {
        text-align: centers;
        background-color: #434547;
        border-color: #434547;
        color: white;
        opacity: 1;
        line-height: 1em;
        position: relative;
        padding: 16px 44px;
        letter-spacing: 2px;
        text-decoration: none;
        margin: 0 auto 15px;
        @include breakpoint(sm){
        }
        font: {
            size: 12px;
            weight: bold;
        }
        cursor: pointer;
        transition: all 0.25s ease;
        &:hover {
            color: white;
            background: #000;
        }
        &:focus{
            background-color: #343434;
            border-color: #343434;
            color: white;
            // padding: 16px 54px 16px 34px;
        }
        &:active {
            //letter-spacing: 2px;
            letter-spacing: 2px;
        }
        &:after {
            content: "SUBMIT";
        }
    }

    // .error {
    //     font-size: 13px;
    //     color: white;
    //     background: red;
    //     @extend %fa-icon;
    //     @extend .fas;
    //     &:after {
    //         @extend %fa-icon;
    //         @extend .fas;
    //         font-family: "Font Awesome 5 Free";
    //         content: fa-content($fa-var-times);
    //     }
    // }
    .hide {
        opacity: 0 !important;
        transition: opacity 0.3s linear;
    }
    .email-status {
        margin-bottom: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
@keyframes rotating {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}