@mixin breakpoint($point) {
    @if $point == sm {
        @media (min-width: 475px){ @content; }
    }  @else if $point == smd {
        @media (min-width: 767px) { @content; }
    }  @else if $point == md {
        @media (min-width: 960px) { @content; }
    } @else if $point == lg {
        @media (min-width: 1260px)  { @content; }
    } @else if $point == xl {
        @media (min-width: 1600px)  { @content; }
    }
}